<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">用户管理</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="filterSetting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
				>
					<template #level="{ value }">
						<span>{{ mapDict('driver_user_level', value) }}</span>
					</template>
					<template #channel="{ value }">
						<span>{{ mapDict('driver_user_channel', value) }}</span>
					</template>
					<template #source="{ value }">
						<span>{{ mapDict('driver_user_source', value) }}</span>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<form-dialog ref="formDialogRef" @refresh="onRefreshData" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'InsuranceCompany',
})
</script>

<script setup name="InsuranceCompany">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { filters, columns } from './config'
import usePage from '@/hooks/usePage'
import { useState, useDict } from '@/utils/hooks.js'

const instance = getCurrentInstance()

const $message = inject('$message')

const formDialogRef = ref(null)

const filterSetting = ref(filters)
const columnSetting = ref(columns)
const settingRef = ref(filters)
const [dict, setDict] = useState({})

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	Request({
		url: 'suf4-asset-service/driver/user/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function findItem(key) {
	return settingRef.value.filters.find(item => item.attr === key)
}

async function fetchDict() {
	const dict = await useDict(['driver_user_channel', 'driver_user_level', 'driver_user_source'])
	if (dict) setDict(dict)
	const channelTarget = findItem('channel')
	channelTarget.options = dict.driver_user_channel || []
	const sourceTarget = findItem('source')
	sourceTarget.options = dict.driver_user_source || []
}

function mapDict(key, value) {
	const e = dict.value[key]
	if (!e) return '--'
	const target = e.find(item => item.code == value)

	return target ? target.name : '--'
}

function initPage() {
	fetchDict()
	onRefreshData()
}

onMounted(async () => {
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
