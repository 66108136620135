export const columns = [
	{
		prop: 'userNo',
		label: 'UserID',
		width: '160px',
	},
	{
		prop: 'level',
		label: '用户级别',
		width: '120px',
	},
	{
		prop: 'nickName',
		label: '用户昵称',
		width: '160px',
	},
	{
		prop: 'mobile',
		label: '手机号',
		width: '160px',
	},
	{
		prop: 'channel',
		label: '注册渠道',
		width: '120px',
	},
	{
		prop: 'source',
		label: '注册来源',
		width: '120px',
	},
	{
		prop: 'referrerId',
		label: '推荐人UserID',
	},
	{
		prop: 'referrerMobile',
		label: '推荐人手机号',
		width: '160px',
	},
	{
		prop: 'unionId',
		label: '关联Union-id',
		width: '275px',
	},
	{
		prop: 'openId',
		label: '关联Open-id',
		width: '275px',
	},
	{
		prop: 'createTime',
		label: '注册时间',
	},
]

export const filters = {
	expand: true,
	filters: [
		{
			label: 'UserID',
			attr: 'userNo',
			type: 'search',
			placeholder: '请输入用户UserID',
			clearable: true,
		},
		{
			attr: 'mobile',
			label: '手机号',
			type: 'search',
			placeholder: '输入用户手机号',
		},
		{
			attr: 'createTimes',
			label: '注册时间',
			type: 'dateTime',
		},
		{
			attr: 'source',
			label: '注册来源',
			type: 'select',
			placeholder: '请选择注册来源',
			options: [],
		},
		{
			attr: 'channel',
			label: '注册渠道',
			type: 'select',
			placeholder: '请选择注册渠道',
			options: [],
		},
		{
			attr: 'referrerMobile',
			label: '推荐人手机号',
			type: 'search',
			placeholder: '输入手机号',
		},
	],
}
